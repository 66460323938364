<template>
  <div>
    <b-modal v-model="infoModale.show">
      <template #modal-title>
        Combien de crédits souhaitez-vous ajouter?
      </template>

      <b-form-group>
        <label>Crédit </label>
        <b-form-input
          v-model="info.credit"
          :type="type"
          min="1"
          max="200"
          class="d-inline-block"
        />
      </b-form-group>

      <template #modal-footer>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-danger btn-sm m-1"
          type="submit"
          @click.prevent="closeModale"
        >
          Fermer
        </button>
        <button
          v-b-modal.modal-close_visit
          class="btn btn-success btn-sm m-1"
          @click.prevent="updateCredit"
        >
          OK
        </button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  props: {
    infoModale: {
      type: Object,
      default() {
        return {}
      },
    },
    messageSuccessCreditUpdate: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      modalShow: false,
      type: 'number',
      info: {
        credit: null,
      },
    }
  },
  methods: {
    updateCredit() {
      const newCredit = Number(this.info.credit)
      this.$store.dispatch('user/updateCredit', { solde: newCredit, id: this.infoModale.id_user })
      this.infoModale.show = false
      this.info.credit = ' '
    },
    closeModale() {
      this.infoModale.show = false
    },
  },
}
</script>

<style scoped>
 .modal-content,.modale{
    margin-top:500px !important;
 }
</style>
